import React from 'react'
import { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import Link from '@material-ui/core/Link'
import { useIntl } from 'gatsby-plugin-intl'
import { CustomButton } from '../CustomButton'
import { MeterSqrIcon } from '../../icons/MeterSqrIcon'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import clsx from 'clsx'
import { submitForm } from '../../utils/submitForm'
import TextField from '@material-ui/core/TextField'
import GoogleMapsAutoComplete from '../AdressAutoComplete'
import { Checkbox, Hidden } from '@material-ui/core'
import theme from '../../Theme/theme'
import api from '../../utils/api'

import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify'
import { handleLeadSubmission, sendLeadEmail } from '../../utils/helper'
import { EMAIL_SUPPORT } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Gotham Book', serif`,
    minHeight: '380px',
    width: '100%',
    backgroundColor: theme.palette.info.main,
    padding: '6em 2em',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: '4em 2em',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(52),
    fontFamily: `'Cormorant Garamond', serif`,
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(46),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(46),
    },
  },
  formControl: {
    margin: theme.spacing(3),
  },
  content: {
    fontSize: '1em',
    marginBottom: theme.spacing(5),
  },
  consent: {
    fontSize: '.8em',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    flexBasis: '60%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '100%',
    },
  },
  consentPart2: {
    marginTop: '1em',
    textAlign: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'left',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  w100: {
    width: '100%',
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(10),
  },
  label: {
    fontFamily: "'Gotham Book', serif",
  },
  labelTypo: {
    fontFamily: "'Gotham Book', serif",
    fontWeight: 100,
    fontSize: '0.8rem',
  },
  btnMargin: {
    marginTop: '3rem',
    [theme.breakpoints.between('xs', 'xs')]: {
      marginTop: '1rem',
    },
  },
  pr: {
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  labelClass: {
    color: '#000',
  },
  inputClass: {
    '& input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.8rem',
    },
    '&::placeholder': {
      fontFamily: "'Gotham Light', serif",
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: '#818181',
    },
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'start',
    flexWrap: 'wrap',
    [theme.breakpoints.between('xs', 'xs')]: {
      flexDirection: 'column',
    },
  },
  checkBoxList: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerClass: {
    marginBottom: 30,
    flex: 1,
  },
  checkBoxSuperLabel: {
    marginTop: '.5rem',
    marginRight: '1rem',
    whiteSpace: 'nowrap',
    fontFamily: `'Gotham Medium', serif`,
    fontWeight: 700,
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  message: {
    fontFamily: `'Gotham Light', serif`,
    marginTop: '1.5rem',
    marginBottom: '1rem',
    fontWeight: 325,
    fontSize: '12px',
  },
  inputPlaceholder: {
    '&::placeholder': {
      fontFamily: "'Gotham Light', serif",
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: '#818181 !important',
    },
  },
  customCheck: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0.5rem',
    alignItems: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
      justifyContent: 'space-between',
      paddingRight: '0rem',
    },
  },
}))

interface EstimateProps {
  defaultOpen?: boolean
}

const Square = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
  margin: 2px;
  background: transparent;
`
const FullSquare = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
  margin: 2px;
  background: #979797;
`
const SquareRed = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid;
  margin: 2px;
  background: transparent;
`

const CustomCheckBox = ({
  error = [''],
  classes = { label: '', labelTypo: '' },
  value = {},
  onChange = () => { },
  name = '',
  label = '',
  checked = false,
}) => (
  <FormControlLabel
    className={clsx(classes.label)}
    control={
      <Checkbox
        icon={error.includes(name) && !error.includes('initial') ? <SquareRed /> : <Square />}
        value={value}
        checkedIcon={<FullSquare />}
        checked={checked}
        onChange={onChange}
        name={name}
      />
    }
    label={<Typography className={classes.labelTypo}>{label}</Typography>}
  />
)

export const Estimate: React.FC<EstimateProps> = (props) => {
  const { defaultOpen } = props
  const intl = useIntl()
  const classes = useStyles()
  const [isExpand, setIsExpand] = useState<boolean>(defaultOpen || false)
  const [error, setError] = useState(['initial'])
  const [inputError, setInputError] = useState<Record<string, string>>({})

  const initialState = {
    propertyType: '',
    adresse: '',
    surfaceHabitable: '',
    etage: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    form: 'FE1',
    consent: false,
  }

  const [submited, setSubmited] = useState(false)

  const [state, setState] = useState({
    propertyType: '',
    adresse: '',
    surfaceHabitable: '',
    etage: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    form: 'FE1',
    consent: false,
  } as any)

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string
        value: unknown
        textContent?: any
        checked?: boolean
      }>,
    ) => {
      if (event && event.target && (event.target.name || event.target.textContent)) {
        setError([])
        setState({
          ...state,
          [event.target.name || ('adresse' as string)]:
            (event.target.name == 'consent' ? event.target.checked : event.target.value) ||
            event.target.textContent,
        })
      }
    },
    [state],
  ) as any

  const onSubmit = async () => {
    setIsExpand(true)
    setSubmited(true)

    const { message, consent, ...keyToValidate } = state

    const validateField = Object.keys(keyToValidate).filter((key: string) => {
      return !state[key] // Retourne les champs vides
    })

    if (validateField.length) {
      setError(validateField)
    } else {
      const detailedMesg = `
        propertyType : ${state.propertyType} , \n
        adresse : ${state.adresse} , \n
        surfaceHabitable : ${state.surfaceHabitable} , \n
        etage : ${state.etage} , \n
        consent : ${state.consent ? true : false} , \n
        message : ${state.message} , \n
        `
      const payload = {
        simpleLead: {
          source: 'Kretz - Estimer mon bien',
          contactFullName: state.firstName + ' ' + state.lastName,
          contactPhoneNumber: state.phone,
          contactEmail: state.email,
          message: detailedMesg,
        },
      }

      try {
        // Handle lead submission using helper method
        const leadResult = await handleLeadSubmission(payload);

        if (leadResult.success) {
          setError([])
          setSubmited(false)
          setState(initialState)
          toast.success(intl.formatMessage({ id: 'new.toast_ok' }), {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
          });


          // Format email in English
          const formattedEmailMsg = `
   Hello ,

   👉 Here are the details of the request:
         <b>Contact:</b> ${state.lastName} ${state.firstName}
         <b>Phone:</b> ${state.phone}
         <b>Email:</b> ${state.email}
         <b>Message:</b> ${state.message}
         <b>PropertyType:</b> ${state.propertyType}
         <b>Address:</b> ${state.adresse}
         <b>Surface:</b> ${state.surfaceHabitable}
         <b>Floor:</b> ${state.etage}


   Please contact this person as soon as possible.

   Best regards,
   The Support Team
   `;


          // Send email using helper method
          const emailResult = await sendLeadEmail(
            formattedEmailMsg,
            payload?.simpleLead?.source,
            EMAIL_SUPPORT
          );  

          if (!emailResult.success) {
            console.log("Email sending failed:", emailResult.error);
          }
        } else {
          throw new Error(leadResult.error?.message || 'Lead submission failed');
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: 'new.toast_error' }), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
        });
      }
    }
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid container sm={11} md={10}>
        <Grid item xs={12} className={classes.heading}>
          {intl.formatMessage({ id: 'new.Formulaire estimation' })}
        </Grid>
        <Grid item md={6} xs={12} className={classes.pr}>
          <div className={classes.checkBoxContainer}>
            <div className={classes.checkBoxSuperLabel}>
              {intl.formatMessage({ id: 'new.Formulaire estimation_2' })}
            </div>
            <div className={clsx(classes.containerClass, classes.checkBoxList)}>
              <Grid container spacing={2} sm={6} md={12}>
                {[
                  'house',
                  'mas',
                  'loft',
                  'castle',
                  'hotelparticulier',
                  'chalet',
                  'villa',
                  'appartment',
                ].map((key) => (
                  <Grid item xs={6} sm={6} md={4} key={key}>
                    <CustomCheckBox
                      classes={classes}
                      error={error}
                      onChange={handleChange}
                      name="propertyType"
                      value={key}
                      checked={state.propertyType === key}
                      label={intl.formatMessage({ id: `propretyType.${key}` })}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          <Grid container sm={11} md={11} justifyContent="space-between">
            <Grid item md={5} xs={12}>
              <TextField
                value={state?.firstName}
                placeholder={intl.formatMessage({ id: 'new.Formulaire estimation_12' })}
                name="firstName"
                error={error.includes('firstName')}
                helperText={inputError.firstName}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    input: classes.inputPlaceholder,
                  },
                }}
                className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={state?.lastName}
                placeholder={intl.formatMessage({ id: 'new.Formulaire estimation_13' })}
                name="lastName"
                error={error.includes('lastName')}
                helperText={inputError.lastName}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    input: classes.inputPlaceholder,
                  },
                }}
                className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
              />
            </Grid>
          </Grid>
          <Grid container sm={11} md={11} justifyContent="space-between">
            <Grid item md={5} xs={12}>
              <TextField
                value={state?.phone}
                placeholder={intl.formatMessage({ id: 'new.Formulaire estimation_14' })}
                name="phone"
                error={error.includes('phone')}
                helperText={inputError.phone}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    input: classes.inputPlaceholder,
                  },
                }}
                className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={state?.email}
                placeholder={intl.formatMessage({ id: 'new.Formulaire estimation_15' })}
                type="email"
                name="email"
                error={error.includes('email')}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    input: classes.inputPlaceholder,
                  },
                }}
                className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
              />
            </Grid>
          </Grid>
          <Grid item md={11}>
            <GoogleMapsAutoComplete
              // value={state?.adresse}
              InputLabelProps={{ className: classes.labelClass }}
              className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
              error={error.includes('adresse')}
              helperText={inputError.adresse}
              onChange={handleChange}
              inputProps={{
                className: classes.inputClass,
              }}
            />
          </Grid>
          <Grid container sm={11} md={11} justifyContent="space-between">
            <Grid item md={5} xs={12}>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className={clsx(classes.containerClass)}
              >
                <NumericFormat
                  value={state?.surfaceHabitable}
                  placeholder={intl.formatMessage({ id: 'new.Formulaire estimation_17' })}
                  name="surfaceHabitable"
                  onValueChange={(values) =>
                    handleChange({ target: { name: 'surfaceHabitable', value: values.value } })
                  }
                  error={error.includes('surfaceHabitable')}
                  helperText={inputError.surfaceHabitable}
                  thousandSeparator=" "
                  decimalSeparator=","
                  allowNegative={false}
                  customInput={TextField}
                  InputProps={{
                    endAdornment: (
                      <span>
                        <MeterSqrIcon />
                      </span>
                    ),
                    classes: {
                      input: classes.inputPlaceholder,
                    },
                  }}
                  className={clsx(classes.w100, classes.inputClass)}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <NumericFormat
                value={state?.etage}
                placeholder={intl.formatMessage({ id: 'new.Formulaire estimation_18' })}
                name="etage"
                onValueChange={(values) =>
                  handleChange({ target: { name: 'etage', value: values.value } })
                }
                error={error.includes('etage')}
                helperText={inputError.etage}
                thousandSeparator=" "
                allowNegative={false}
                customInput={TextField}
                InputProps={{
                  classes: {
                    input: classes.inputPlaceholder,
                  },
                }}
                className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container>
            <Grid xs={12} item className={clsx(classes.message)}>
              {intl.formatMessage({ id: 'new.Formulaire estimation_19' })}
            </Grid>
            <Grid xs={12} item>
              <TextareaAutosize
                value={state.message}
                className={clsx(classes.w100, classes.transparent, classes.mb2)}
                aria-label="minimum height"
                name="message"
                onChange={handleChange}
                minRows={18}
                style={{ resize: 'none' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid md={9} xs={12} container className={classes.consent} justifyContent="center">
        <Grid container md={12} sm={12}>
          <Grid item xs={1} className={classes.customCheck}>
            <Checkbox
              size="small"
              icon={
                state.consent == false && !error.includes('initial') ? <SquareRed /> : <Square />
              }
              checkedIcon={<FullSquare />}
              checked={state.consent}
              onChange={handleChange}
              name={'consent'}
              style={{ padding: '0 0 0 0px' }}
            />
          </Grid>
          <Grid item xs={11}>
            {intl.formatMessage({ id: 'new.Formulaire estimation_20' })}
          </Grid>
        </Grid>
        <Grid container md={12} className={classes.consentPart2}>
          <Hidden mdUp>
            <Grid item xs={1}></Grid>
          </Hidden>
          <Grid item xs={11} md={12}>
            <span>
              {intl.formatMessage({ id: 'new.Formulaire estimation_21' })}
              <Link href={'/' + intl.locale + '/legal-privacy-policy'} underline="always">
                {intl.formatMessage({ id: 'clickHere' })}
              </Link>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid md={9} xs={12} container className={classes.btnMargin} justifyContent="center">
        <Grid style={{ textAlign: 'center' }}>
          {submited ? (
            intl.formatMessage({ id: 'new.Formulaire estimation_22' })
          ) : (
            <CustomButton
              onClick={onSubmit}
              textTransform="inherit"
              darkBoarder
              isDarkBackground={true}
            //height={isExpand ? '40px' : '50px'}
            >
              <span
                style={{
                  fontSize: '0.8rem',
                  padding: '0 0px',
                  fontWeight: 100,
                  textTransform: 'uppercase',
                }}
              >
                {intl.formatMessage({ id: isExpand ? 'send' : 'send' })}
              </span>
            </CustomButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Estimate
